import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import { useSnapshot } from 'valtio'
import { SymbolQuoteList } from '../win988/component/SymbolQuoteList'
import { SymbolChangeRanking } from '~/modules/investment-consultant/symbolList/list/SymbolChangeRankingList'
import { QuoteDetail } from '../win988/component/QuoteDetail'

const symbols = ['2356', '1590', '2303', '2609', '3481', '1605', '3231', '6282', '2618']

export const Revive_SidePane1 = memo<ReactProps>(function Revive_SidePane1() {
  const charting = useSnapshot(store.charting)
  return (
    <styleds.container>
      <styleds.card height={30}>
        <TitleSymbolQuote.Default symbol={charting.symbol} />
      </styleds.card>
      <styleds.card height={160}>
        <IntradayTrendChart symbol={charting.symbol} />
      </styleds.card>

      <styleds.card height={148}>
        <QuoteDetail symbol={[charting.symbol]} />
      </styleds.card>
      <styleds.card height={40}>精選股列表</styleds.card>
      <styleds.card height={500}>
        <SymbolChangeRanking
          useChart={store.charting}
          symbolNumber={symbols}
          checkTrendMode='NoCheck'
          countArray={[1, 5, 20, 60]}
        />
      </styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
  card: styled.div<{ height: number }>`
    ${fill_vertical_all_center};
    height: ${props => props.height}px;
    background-color: #25262a;
    border-radius: 8px;
  `,
}
