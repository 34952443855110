import { css } from '@emotion/react'
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import WatchSymbolToggleButtonSingle from '../heineken_template/_col/col_WatchSymbolToggleSingleButton'
import SymbolCardRankingBody from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'

export const daddy960_revive_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${SymbolCardRankingBody.classes.containerDefault} {
        background-color: #24252b;
        border: 1px solid #444444;
        color: #ffffff;
        padding: 0px 2px;
        &:hover {
          background-color: #35363c;
        }
      }
    }
  `
}
