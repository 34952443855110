import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { store } from '../heineken_template/_private/store'
import { SymbolQuoteList } from '../win988/component/SymbolQuoteList'
import { WatchListAddButton } from '../win988/win988_SidePane1'
import { SymbolSearch } from './component/SymbolSearch'

const symbols = ['2356', '1590', '2303', '2609', '3481', '1605', '3231', '6282', '2618']

export const Revive_SidePane2 = memo<ReactProps>(function Revive_SidePane2() {
  const { state: watchListState, acts: watchListActs } = useSymbolWatchList('stocknerve_group_1')

  const symbol = useSnapshot(store.charting).symbol

  return (
    <styleds.container>
      <styleds.searchTitle>個股搜尋</styleds.searchTitle>
      <styleds.searchContent>
        <SymbolSearch />
      </styleds.searchContent>
      <styleds.watchlistButton>
        <WatchListAddButton
          watchListActs={watchListActs}
          watchListState={watchListState}
          currentSymbol={symbol}
        />
      </styleds.watchlistButton>
      {/* <SymbolQuoteList symbol={symbols} /> */}
      <styleds.symbolListContent>
        <WatchListWithChart
          groupName={'stocknerve_group_1'}
          useChart={store.charting}
        />
      </styleds.symbolListContent>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
  watchlistButton: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
  searchTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #353535;
    border-radius: 4px;
  `,
  searchContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: #252525;
  `,
  symbolListContent: styled.div`
    width: 100%;
    height: calc(100% - 400px);
  `,
}
