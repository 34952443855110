import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'
import { crocodiles, CrocodilesParams } from '../god/crocodiles'

export const win988_intradayShort = createIndicator({
  id: 'win988-intradayShort',
  displayName: '極短當沖',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //#region
      const _calcIndicator = (params: CrocodilesParams) => {
        const { biasLength, percentRLength, rsiLength, kdLength } = params
        const series = context.new_var(
          crocodiles(
            resolution as '1' | '5' | '15',
            this.bias(biasLength),
            this.percentR(percentRLength),
            this.rsi(close, rsiLength),
            this.stochastic(kdLength, 3, 3).k,
          ),
        )
        series.get(100)
        return series
      }

      const resolution = context.symbol.period
      const MAX_ENTRIES_TODAY = 10
      const STOP_LOSS = 50
      const TAKE_PROFIT = 30

      const longThreshold1 = 15
      const shortThreshold1 = 85
      const longThreshold5 = 15
      const shortThreshold5 = 85
      const longThreshold15 = 15
      const shortThreshold15 = 85

      const position = context.new_var()
      const entryPrice = context.new_var()
      const exitPrice = context.new_var()
      const entriesToday = context.new_var()

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volume = context.new_var(this.PineJS.Std.volume(context))
      const datetime = dayAPI(this.PineJS.Std.time(context))

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)
      exitPrice.get(100)

      close.get(100)
      high.get(100)
      low.get(100)
      volume.get(100)

      if (datetime.hour() === 8 && datetime.minute() === 45) {
        entriesToday.set(0)
      }
      const volumeThreshold = this.average(volume, 6) + this.average(volume, 12)

      const biasLength = 12
      const percentRLength = 13
      const rsiLength = 6
      const kdLength = 9

      let params1Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params1Min = {
          biasLength: biasLength,
          percentRLength: percentRLength,
          rsiLength: rsiLength,
          kdLength: kdLength,
        }
      } else if (resolution === '5') {
        params1Min = {
          biasLength: Math.ceil(biasLength / 5),
          percentRLength: Math.ceil(percentRLength / 5),
          rsiLength: Math.ceil(rsiLength / 5),
          kdLength: Math.ceil(kdLength / 5),
        }
      } else if (resolution === '15') {
        params1Min = {
          biasLength: Math.ceil(biasLength / 15),
          percentRLength: Math.ceil(percentRLength / 15),
          rsiLength: Math.ceil(rsiLength / 15),
          kdLength: Math.ceil(kdLength / 15),
        }
      }

      let params5Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params5Min = {
          biasLength: biasLength * 5,
          percentRLength: percentRLength * 5,
          rsiLength: rsiLength * 5,
          kdLength: kdLength * 5,
        }
      } else if (resolution === '5') {
        params5Min = {
          biasLength: biasLength,
          percentRLength: percentRLength,
          rsiLength: rsiLength,
          kdLength: kdLength,
        }
      } else if (resolution === '15') {
        params5Min = {
          biasLength: Math.ceil(biasLength / 3),
          percentRLength: Math.ceil(percentRLength / 3),
          rsiLength: Math.ceil(rsiLength / 3),
          kdLength: Math.ceil(kdLength / 3),
        }
      }

      let params15Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params15Min = {
          biasLength: biasLength * 15,
          percentRLength: percentRLength * 15,
          rsiLength: rsiLength * 15,
          kdLength: kdLength * 15,
        }
      } else if (resolution === '5') {
        params15Min = {
          biasLength: biasLength * 3,
          percentRLength: percentRLength * 3,
          rsiLength: rsiLength * 3,
          kdLength: kdLength * 3,
        }
      } else if (resolution === '15') {
        params15Min = {
          biasLength: biasLength * 15,
          percentRLength: percentRLength * 15,
          rsiLength: rsiLength * 15,
          kdLength: kdLength * 15,
        }
      }
      const indicator1Min = _calcIndicator(params1Min)
      const indicator5Min = _calcIndicator(params5Min)
      const indicator15Min = _calcIndicator(params15Min)

      const timeCondition = datetime.hour() <= 13 && datetime.hour() >= 8
      const timeConditionPM =
        (datetime.hour() >= 15 && datetime.hour() < 24) ||
        (datetime.hour() >= 0 && datetime.hour() <= 4)

      const longEntry =
        (timeCondition || timeConditionPM) &&
        indicator1Min.get(0) < longThreshold1 &&
        indicator5Min.get(0) < longThreshold5 &&
        indicator15Min.get(0) < longThreshold15 &&
        entriesToday.get(0) < MAX_ENTRIES_TODAY

      const shortEntry =
        (timeCondition || timeConditionPM) &&
        indicator1Min.get(0) > shortThreshold1 &&
        indicator5Min.get(0) > shortThreshold5 &&
        indicator15Min.get(0) > shortThreshold15 &&
        entriesToday.get(0) < MAX_ENTRIES_TODAY

      if (longEntry) {
        position.set(1)
      }

      if (shortEntry) {
        position.set(-1)
      }

      // ---------------------------------------- Entry ----------------------------------------
      if (position.get(0) === 1 && position.get(1) !== 1) {
        entriesToday.set(entriesToday.get(0) + 1)
        entryPrice.set(close.get(0))
      }
      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        // Take Profit
        if (high.get(0) > entryPrice.get(1) + TAKE_PROFIT) {
          position.set(0)
          exitPrice.set(entryPrice.get(1) + TAKE_PROFIT)
        }
        // stoploss
        if (low.get(0) < entryPrice.get(1) - STOP_LOSS) {
          position.set(0)
          exitPrice.set(entryPrice.get(1) - STOP_LOSS)
        }
        //13:30
        if (datetime.hour() === 13 && datetime.minute() === 30) {
          position.set(0)
          exitPrice.set(close.get(0))
        }
        //4:50
        if (datetime.hour() === 4 && datetime.minute() === 50) {
          position.set(0)
          exitPrice.set(close.get(0))
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        //固定停利
        if (low.get(0) < entryPrice.get(1) - TAKE_PROFIT) {
          position.set(0)
          exitPrice.set(entryPrice.get(1) - TAKE_PROFIT)
        }

        // stop loss
        if (high.get(0) > entryPrice.get(1) + STOP_LOSS) {
          position.set(0)
          exitPrice.set(entryPrice.get(1) + STOP_LOSS)
        }
        //13:30
        if (datetime.hour() === 13 && datetime.minute() === 30) {
          position.set(0)
          exitPrice.set(close.get(0))
        }

        //04:50
        if (datetime.hour() === 4 && datetime.minute() === 50) {
          position.set(0)
          exitPrice.set(close.get(0))
        }
      }
      //#endregion

      const dayTradeAvgPrice = this.dayAvgPrice(0)

      const colorIndex = () => {
        if (position.get(0) === 1) return 0
        else if (position.get(0) === -1) return 3
        else return 4
      }

      return [dayTradeAvgPrice, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          visible: true,
          linewidth: 2,
          plottype: 0,
          trackPrice: false,
          transparency: 30,
          color: '#386eac',
        },
      },
    },
    styles: {
      plot_0: {
        title: '成本線',
        histogramBase: 0,
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
