import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const win988_positionChip1H = createIndicator({
  id: 'win988-position-chip-1h',
  displayName: '籌碼波段',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const marketposition = this._context.new_var()

      const close = this.ohlc.close
      const closeArray = this.ohlc.closeArray
      const low = this.ohlc.low
      const high = this.ohlc.high
      const dayTradeAvgPrice = this.dayAvgPrice(0)

      const ma = this.PineJS.Std.sma(closeArray, 60, this._context)

      if (low > ma && low > dayTradeAvgPrice && close > low) {
        marketposition.set(1)
      } else if (high < ma && high < dayTradeAvgPrice && close < high) {
        marketposition.set(-1)
      } else marketposition.set(0)

      const colorIndex = () => {
        if (marketposition.get(0) === 1) return 0
        else if (marketposition.get(0) === -1) return 3
        else return 4
      }

      return [dayTradeAvgPrice, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      { id: 'plot_0', type: 'line' },
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          visible: true,
          linewidth: 2,
          plottype: 0,
          trackPrice: false,
          transparency: 30,
          color: '#386eac',
        },
      },
    },
    styles: {
      plot_0: {
        title: '成本線',
        histogramBase: 0,
      },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#df484c',
            width: 1,
            style: 0,
          },
          1: {
            color: '#e39e9c',
            width: 1,
            style: 0,
          },
          2: {
            color: '#91b48a',
            width: 1,
            style: 0,
          },
          3: {
            color: '#5cb642',
            width: 1,
            style: 0,
          },
          4: {
            color: '#aaaaaa',
            width: 1,
            style: 0,
          },
        },
      },
    },
    inputs: [],
  },
})
