import { css } from '@emotion/react'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import TradingPlatfrom from '../daddy960_tc1688/component/TradingPlatform'

export const asia71178_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${TradingPlatfrom.classes.container} {
        &.light {
          box-shadow: 0px 1px 5px 1px #cccccc;
        }
      }

      ${TradingPlatfrom.classes.header} {
        &.light {
          background-color: #e4e3e3;
        }
      }
    }
  `
}
