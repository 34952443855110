import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { AiOutlineStar, AiFillStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
} from '~/modules/AppLayout/FlexGridCss'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { useSnapshot } from 'valtio'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { VscError } from 'react-icons/vsc'
import { tradingPlatfromStore } from './TradingPlatform'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { store } from '~/pages/heineken_template/_private/store'

const TradingPlatformHeader = memo<ReactProps>(function TradingPlatformHeader() {
  const theme = useThemeStore(s => s.theme)
  const iconFill = theme === 'dark' ? '#ffffff' : '#111111'

  return (
    <classes.Root>
      <classes.container
        css={css`
          width: 98%;
          border-radius: 0px;
          border-bottom: 1px solid #666666;
        `}
      >
        <p>
          <AiOutlineHeart
            size={16}
            fill={iconFill}
          />
        </p>
        <p>商品</p>
        <p>價格</p>
        <p>漲跌</p>
        <p>幅度</p>
      </classes.container>
    </classes.Root>
  )
})

//要把附帶圖表的整合再一起TradingPlatformSymbolChart
const TradingPlatformSymbol = memo<
  ReactProps<{ symbol: Signalr.SymbolString; watchListGroupName: string; listMaxSize: number }>
>(function TradingPlatformSymbol(props) {
  const theme = useThemeStore(s => s.theme)
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  //signalrStore2
  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [props.symbol])

  const quote = useSnapshot(signalrStore2.values.quote)[props.symbol]

  //watchList
  const {
    state: { symbolListArray: watchList },
    acts,
  } = useSymbolWatchList(props.watchListGroupName)

  const hadSymbol = watchList?.includes(props.symbol)

  const charting = useSnapshot(store).charting
  const isAvtive = props.symbol === charting.symbol ? '-active' : ''

  //已在自選清單內所顯示的icon
  const symbolListState = useSnapshot(tradingPlatfromStore).symbolListState
  const selectSymbol =
    symbolListState === 'watchList' ? (
      <VscError
        size={16}
        fill={classesFill.iconRemoveFill}
      />
    ) : (
      <AiFillHeart
        size={16}
        fill={classesFill.iconDefaultFill}
      />
    )

  return (
    <classes.Root css={baseStyled}>
      <classes.container
        className={theme + isAvtive}
        onClick={() => {
          store.charting.changeSymbol(props.symbol)
        }}
      >
        <classes.icon
          onClick={event => {
            /** 阻止加入商品至自選股時執行父元素的切換商品事件 */
            event.stopPropagation()

            if (hadSymbol) {
              acts.removeSymbol(props.symbol)
            }
            if (!hadSymbol) {
              acts.addSymbol(props.symbol, props.listMaxSize ?? 100)
            }
          }}
        >
          {hadSymbol ? (
            selectSymbol
          ) : (
            <AiOutlineHeart
              size={16}
              fill={classesFill.iconDefaultFill + 'aa'}
            />
          )}
        </classes.icon>
        <p
          css={css`
            justify-content: start !important;
            white-space: nowrap;
            font-size: 13px;
          `}
        >
          {props.symbol}
          {dictionary[props.symbol ?? ''].replace('指數', '')}
        </p>
        <classes.item>
          <QuoteValueItem.ohlc.Display
            quote={quote}
            ohlcType='close'
          />
        </classes.item>
        <classes.item>
          <QuoteValueItem.change.Display
            quote={quote}
            changeType='closeChange'
          />
        </classes.item>
        <classes.item>
          <QuoteValueItem.change.Display
            quote={quote}
            changeType='closeChangePercent'
            unit='%'
          />
        </classes.item>
      </classes.container>
    </classes.Root>
  )
})

const classesFill = {
  iconRemoveFill: '#992323',
  iconDefaultFill: '#6d9af0',
}

const classes = {
  Root: styled.div`
    ${fill_vertical_all_center};
    height: 32px;
    width: 100%;
    flex-shrink: 0;
  `,
  container: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 10% 32% 20% 20% 18%;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    user-select: none;
    & > p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      margin: 0;
    }
  `,
  item: styled.p`
    display: flex;
    flex-direction: row;
    justify-content: end !important;
    margin-right: 8px !important;
    line-height: 16px;
    margin: 0;
  `,
  icon: styled.p`
    cursor: pointer;
    position: relative;

    &:hover {
      & > * {
        height: 20px;
        width: 20px;
        transition: 0.3s;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 0 6px 10px ${classesFill.iconDefaultFill}cc;
      }

      &:active:after {
        box-shadow: 0 0 0 0 ${classesFill.iconDefaultFill}cc;
        position: absolute;
        border-radius: 50%;
        opacity: 1;
        transition: 0s;
      }

      &:active {
        top: 1px;
      }
    }
  `,
}

export default {
  Body: TradingPlatformSymbol,
  Header: TradingPlatformHeader,
  classes,
  classesFill,
}

const baseStyled = css`
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      color: #fafafa;
      &:hover {
        background-color: #282930;
      }
    }
    &.light {
      color: #252525;
      &:hover {
        background-color: #eaeaea;
      }
    }
    &.dark-active {
      color: #fafafa;
      background-color: #353535;
      &:hover {
        background-color: #282930aa;
      }
    }
    &.light-active {
      color: #252525;
      background-color: #f3f3f3;
      &:hover {
        background-color: #eaeaeaaa;
      }
    }
  }
`
