import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { asia71178_store } from '~/pages/asia71178/asia71178_store'
import dayAPI from '~/utils/dayAPI'

export const asia71178_score = createIndicator({
  id: 'asia71178-score',
  displayName: 'Score',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //#region Init varaibles
      let score = 0

      const sessionType =
        context.symbol.ticker === 'TX-1'
          ? SessionType.TAIFEX
          : context.symbol.ticker === 'TXAM-1'
          ? SessionType.TAIFEX_AM
          : SessionType.TWSE

      const resolution = context.symbol.period

      const close = this.ohlc.closeArray

      const _updateScore = (score_: number) => {
        asia71178_store.scores.unshift({
          score: score_,
          date: dayAPI(this.PineJS.Std.time(context)).format('MM/DD'),
        })
        if (asia71178_store.scores.length > 7) asia71178_store.scores.pop()
      }

      close.get(100)

      //#endregion

      //#region Indicator Parameters
      const wmaLength = 10 //9
      const kdLength = 8 //7
      const rsvt = 8 //7
      const kt = 12 //11
      const bbandLength = 12 //11
      const stds = 2 //7
      //#endregion

      //#region Indicators

      const wma =
        resolution === '1D'
          ? context.new_var(this.wma(close, wmaLength))
          : context.new_var(this.wma(close, wmaLength * 5))

      const stochastic =
        resolution === '1D'
          ? this.stochastic(kdLength, rsvt, kt)
          : this.stochastic(kdLength * 5, rsvt * 5, kt * 5)

      const bband =
        resolution === '1D'
          ? this.bollingerBand(close, bbandLength, stds)
          : this.bollingerBand(close, bbandLength * 5, stds)

      const wma60 =
        resolution === '1D'
          ? context.new_var(this.wma(close, Math.ceil(wmaLength / 5)))
          : context.new_var(this.wma(close, wmaLength))

      const stochastic60 =
        resolution === '1D'
          ? this.stochastic(Math.ceil(kdLength / 5), Math.ceil(rsvt / 5), Math.ceil(kt / 5))
          : this.stochastic(kdLength, rsvt, kt)

      const bbup = context.new_var(bband.top)

      wma.get(10)
      bbup.get(10)
      wma60.get(100)

      //#endregion

      //#region Score logics

      const conditionBBandUp = close.get(0) > bband.top
      const conditionBBandDn = close.get(0) < bband.bottom
      const conditionDailyKDUp = stochastic.k > stochastic.d
      const conditionDailyKDDn = stochastic.k < stochastic.d
      const condition60KDUp = stochastic60.k > stochastic60.d
      const condition60KDDn = stochastic60.k < stochastic60.d
      const conditionWmaDailyUp = close.get(0) > wma
      const conditionWmaDailyDn = close.get(0) < wma
      const conditionWma60Up = close.get(0) > wma60.get(0) //&& close.get(1) < wma60.get(1)
      const conditionWma60Dn = close.get(0) < wma60.get(0) //&& close.get(1) > wma60.get(1)

      if (resolution === '1D' || (resolution === '60' && this.isSessionLastBar(sessionType))) {
        if (conditionBBandUp) {
          score += 1
        }

        if (conditionBBandDn) {
          score -= 1
        }

        if (conditionDailyKDUp) {
          score += 1
        }

        if (conditionDailyKDDn) {
          score -= 1
        }

        if (conditionWmaDailyUp) {
          score += 1
        }

        if (conditionWmaDailyDn) {
          score -= 1
        }

        if (condition60KDUp) {
          score += 1
        }

        if (condition60KDDn) {
          score -= 1
        }

        if (conditionWma60Up) {
          score += 1
        }

        if (conditionWma60Dn) {
          score -= 1
        }

        console.log({
          date: dayAPI(this.PineJS.Std.time(context)).format('MM/DD'),
          score: score,
          detail: {
            BB: conditionBBandUp ? 1 : conditionBBandDn ? -1 : 0,
            'WMA(D)': conditionWmaDailyUp ? 1 : conditionWmaDailyDn ? -1 : 0,
            'WMA(60)': conditionWma60Up ? 1 : conditionWma60Dn ? -1 : 0,
            'KD(D)': conditionDailyKDUp ? 1 : conditionDailyKDDn ? -1 : 0,
            'KD(60)': condition60KDUp ? 1 : condition60KDDn ? -1 : 0,
          },
        })

        _updateScore(score)
      }

      asia71178_store.buildUp = resolution === '1D' ? bbup.get(1) : bbup.get(6)
      asia71178_store.buildDn = resolution === '1D' ? wma.get(1) : wma.get(6)

      //#endregion
      // for (let i = 0; i < asia71178_store.scores.length; i++) {
      //   const s = asia71178_store.scores[i]
      //   console.log(`
      //   ${i}. ${s.date.toDate().toLocaleDateString()}: ${s.score}
      //   `)
      // }

      const colorIndex = score >= 0 ? 0 : 1
      return [score, colorIndex, 5, -5]
    },
  },

  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      {
        id: 'score',
        type: 'line',
      },
      {
        id: 'scorePalette',
        type: 'colorer',
        target: 'score',
        palette: 'scorePalette',
      },
      { id: 'upLimit', type: 'line' },
      { id: 'dnLimit', type: 'line' },
    ],
    palettes: {
      scorePalette: {
        colors: {
          0: {
            name: '市場多方能量',
          },
          1: {
            name: '市場空方能量',
          },
        },
      },
    },
    defaults: {
      styles: {
        score: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#000000',
        },
        upLimit: {
          linestyle: 1,
          linewidth: 1,
          plottype: 0,
          trackPrice: false,
          transparency: 50,
          visible: true,
          color: '#dd5441',
        },
        dnLimit: {
          linestyle: 1,
          linewidth: 1,
          plottype: 0,
          trackPrice: false,
          transparency: 50,
          visible: true,
          color: '#00aa0a',
        },
      },
      palettes: {
        scorePalette: {
          colors: {
            0: {
              color: '#dd5441',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00aa0a',
              width: 0,
              style: 0,
            },
          },
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      score: {
        title: '分數',
        histogramBase: 0,
        joinPoints: false,
      },
      upLimit: {
        title: '正數滿分',
        histogramBase: 0,
        joinPoints: false,
      },
      dnLimit: {
        title: '負數滿分',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [],
  },
})
