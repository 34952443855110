import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'

import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { store } from '../heineken_template/_private/store'
import { win988_strategiesBandGroup, win988_strategiesDayTradeGroup } from './win988_initStrategies'

export const Win988_SidePane2 = memo<ReactProps>(function Win988_SidePane2() {
  const charting = useSnapshot(store.charting)

  return (
    <styleds.container>
      <div
        css={css`
          width: 100%;
          gap: 2px;
        `}
      >
        <styleds.strategyGroupHeader>當沖策略</styleds.strategyGroupHeader>
        <styleds.strategyGroupCard>
          {win988_strategiesDayTradeGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName

            return (
              <styleds.strategyButtton
                isActive={isActive}
                key={config.displayName}
                onClick={() => {
                  store.charting.setStrategy(config)
                  store.charting.updateFromStrategyState()
                }}
              >
                {config.displayName}
              </styleds.strategyButtton>
            )
          })}
        </styleds.strategyGroupCard>
      </div>
      <div
        css={css`
          width: 100%;
          gap: 2px;
        `}
      >
        <styleds.strategyGroupHeader>波段策略</styleds.strategyGroupHeader>
        <styleds.strategyGroupCard>
          {win988_strategiesBandGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName

            return (
              <styleds.strategyButtton
                isActive={isActive}
                key={config.displayName}
                onClick={() => {
                  store.charting.setStrategy(config)
                  store.charting.updateFromStrategyState()
                }}
              >
                {config.displayName}
              </styleds.strategyButtton>
            )
          })}
        </styleds.strategyGroupCard>
      </div>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-image: linear-gradient(to bottom, #26272d, #111317);
    padding: 4px;
    gap: 8px;
  `,
  strategyGroupHeader: styled.div`
    ${fill_horizontal_all_center};
    width: 100%;
    height: 36px;
    background-image: linear-gradient(177deg, #52545f, #3b3c44);
    border-radius: 4px;
  `,
  strategyGroupCard: styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: calc(50% - 2px) calc(50% - 2px);
    grid-gap: 4px;
    background-image: linear-gradient(to bottom, #333333, #323338);
    border-radius: 8px;
    padding: 4px;
  `,
  strategyButtton: styled.div<{ isActive: boolean }>`
    ${fill_horizontal_all_center};
    height: 48px;
    width: 100%;
    border-radius: 4px;
    background-image: ${props =>
      props.isActive === false
        ? 'linear-gradient(172deg, #17181e, #1f2025)'
        : 'linear-gradient(172deg,  #28282d, #2b2d35)'};
    border: 1px solid ${props => (props.isActive === false ? '#333333' : '#666666')};
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      background-image: linear-gradient(172deg, #27282e, #1f2025);
    }
  `,
}
