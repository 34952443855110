import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'
import  TradingPlatfrom from './component/TradingPlatform'

const symbols = ['CN-1', 'HSI-1', 'NK-1', 'NQ-1', 'YM-1', 'FDX-1', 'GC-1', 'SI-1']

const watchListGroup = 'tc1688_group_1'

export const Tc1688_SidePane1 = memo<ReactProps>(function Tc1688_SidePane1() {
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        padding: 4px;
        height: calc(100% - 48px);
      `}
    >
      <TradingPlatfrom.Display
        symbolArray={symbols}
        watchListGroup={watchListGroup}
      />
    </div>
  )
})
