import { css } from '@emotion/react'
import { Autocomplete } from '@mantine/core'
import { memo } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useSnapshot } from 'valtio'
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore'
import { store } from '~/pages/heineken_template/_private/store'

type SymbolKv = {
  symbol: string
  name: string
}

export const SymbolSearch = memo<ReactProps>(function SymbolSearch() {
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  const stockSymbols: SymbolKv[] = Object.entries(dictionary).map(s => ({
    symbol: s[0],
    name: s[1],
  }))

  const symbol = useSnapshot(store.charting).symbol
  return (
    <Autocomplete
      icon={<AiOutlineSearch size={22} />}
      placeholder='商品代號'
      data={stockSymbols.map(s => s.symbol + s.name)}
      variant='unstyled'
      limit={100}
      maxDropdownHeight={200}
      //onChange => 只要輸入完4位數代號就會切換
      onItemSubmit={item => {
        const symbolNumber = String(parseInt(item.value, 10))
        symbolNumber.match(/^[\d]{4}$/) && store.charting.changeSymbol(symbolNumber)
      }}
      css={css`
        width: 100%;
      `}
    />
  )
})
