import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { TemplateProps } from '../heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import { css } from '@emotion/react'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { Daddy960_Footer } from '../daddy960_opkevin/component/daddy960_Footer'
import { fr_serverTime } from '../heineken_template/_fr/fr_serverTime'
import { fr_me } from '../heineken_template/_fr/fr_me'
import { AgentProduct } from '~/configs/AgentProduct'
import dayAPI from '~/utils/dayAPI'
import { Daddy960_TopBar } from '../daddy960_opkevin/component/daddy960_TopBar'
import { Daddy960LoginView } from '../daddy960_opkevin/component/Daddy960LoginView'
import { Revive_SidePane1 } from './revive_SidePane1'
import { Revive_SidePane2 } from './revive_SidePane2'
import { daddy960_revive_initStrategies } from './daddy960_revive_initStrategies'
import { daddy960_revive_initStyling } from './daddy960_revive_initStyling'

export const daddy960_revive_init = (templateProps: TemplateProps) => {
  daddy960_revive_initStyling(templateProps)
  daddy960_revive_initStrategies()

  useThemeStore.setState({ theme: 'dark' })
  store.charting.setThemeMode('dark')

  // templateProps.permissions.pageview =
  // meCheckHandlerCreateByProductNameWithExpiredAt('web_revive')
  templateProps.hooks.add(fr_serverTime.useInstall)
  templateProps.hooks.add(fr_me.useInstall)

  templateProps.permissions.pageview =
    fr_me.toTemplate.permissions.hasPermissionOrHasLoginBeforeServerDate({
      agentProduct: AgentProduct['daddy960@web_revive'],
      date: dayAPI('2023/04/01 06:00'),
    })

  //store.charting.calcFrom = dayAPI().subtract(3, 'day')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'TX-1',
    interval: '60',
    enableVolumeIndicator: false,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledPaneMenu: true,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: false,
    disabledHeaderSaveload: false,
    disabledHeaderCompare: false,
    overrides: {
      ...store.charting.darkOverrides,
      'paneProperties.legendProperties.showLegend': false,
    },
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 48px calc(100vh - 48px) 1fr;
    grid-template-columns: 336px 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Drawer2 Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css`
      grid-template-rows: 48px calc(100% - 56px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px calc(100% - 56px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `

  // Init components
  templateProps.layout.Row1 = (
    <Daddy960_TopBar
      leftBurger={true}
      product={'revive'}
    />
  )
  templateProps.layout.Row2 = Daddy960_Footer
  templateProps.layout.Drawer1 = Revive_SidePane1
  templateProps.layout.Drawer2 = Revive_SidePane2
  templateProps.layout.login = (
    <Daddy960LoginView
      product='stocknerve' //revive
      faviconUrl='stocknerve/favicon.png'
      lineLink='https://page.line.me/?accountId=730hvfcs'
    />
  )
}
